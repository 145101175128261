$(document).ready(function(){
	// $('#products-list').owlCarousel({
	// 	autoplay:false,
	// 	loop:true,
	// 	margin:10,
	// 	nav:false,
	// 	dots: true,
	// 	animateOut: 'slideOutLeft',
	// 	animateIn: 'slideInRight',
	// 	mouseDrag: false,
	// 	autoplayTimeout:4000,
	// 	smartSpeed:4000,
	// 	responsiveClass:true,
	// 	responsive:{
	// 		0:{
	// 			items:1
	// 		},
	// 		650:{
	// 			items:2
	// 		},
	// 		992:{
	// 			items:2
	// 		},
	// 		1000:{
	// 			items:3
	// 		}
	// 	}
	// })

});
